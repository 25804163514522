// Things related to Downloads page
const Downloads = {
	fillTable: async () => 
		getEntriesAndFillTable(
			getel('DownloadsTableBody'),
			entry => (entry.entryValue.File?.ArkioExport != null || entry.entryValue.File?.ArkioPhotoExport != null), null,
			(tableBod, tableDat) => fillTableWithEntries(tableBod, tableDat,
				{ enableDownload : true }, {onErr : CP.showErrorMessage, onWarn : CP.showWarningMessage})),
	bodyOnLoad: () => {
		//TODO maybe do more things in DOMContentLoaded
		CP.initMsgManager(getel('errorArea'), null, null);
		initPage();
		Downloads.fillTable();
	}
};
